// src/Landing_page/HeroSection.js
import React from 'react';
import styled from 'styled-components';
import ActionButton from '../components/ActionButton';
import heroImage from './assets/HeroImage.png';
import titleImage from './assets/website_title.png';

const Hero = styled.div`
  background-color: rgba(226, 255, 190, 0.816);
  min-height: 120vh;
  border-bottom: 2px dotted #616161;
  border-right: 2px dotted #616161;
  border-bottom-right-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 20px 80px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    min-height: 100vh;
    align-items: center;
    padding: 60px 20px 100px;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 40px;
  }
`;

const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    align-items: flex-start;
    flex: 1;
  }
`;

const TitleImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  margin-top: -20px;

  @media (min-width: 768px) {
    margin-top: -40px;
  }
`;

const HeroParagraph = styled.div`
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.5;
  max-width: 500px;
  text-align: left;
  align-self: flex-start;
  width: 100%;

  p {
    margin: 0 0 10px 0;
  }

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const HeroImage = styled.div`
  width: 100%;
  max-width: 400px;
  margin-top: 20px;

  @media (min-width: 768px) {
    flex: 1;
    margin-top: 40px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const HeroSection = () => {
  return (
    <Hero>
      <HeroContent>
        <HeroText>
          <TitleImage src={titleImage} alt="Send due unlimited WhatsApp messages in due time" />
          <HeroParagraph>
            <p>No need to use your personal number.</p>
            <p>Use the Chrome extension to extract contacts.</p>
            <p>Create a business profile and post.</p>
            <p>Just add the leads, business profile, and post,</p>
            <p>and we will send the messages for you.</p>
          </HeroParagraph>
          <ButtonWrapper>
            <ActionButton href="https://app.sen-due.com/campaign/steps/leads">
              Start Campaign
            </ActionButton>
          </ButtonWrapper>
        </HeroText>
        <HeroImage>
          <img src={heroImage} alt="Hero" />
        </HeroImage>
      </HeroContent>
    </Hero>
  );
};

export default HeroSection;