// src/components/ActionButton.js
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: #000000;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  width: ${props => props.width || '200px'};
  height: ${props => props.height || '50px'};
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      to right, 
      #000000 0%,
      #000000 25%,
      rgba(0, 255, 0, 0.5) 50%,
      #000000 75%,
      #000000 100%
    );
    transition: left 0.7s ease, opacity 0.3s ease;
    z-index: 1;
    opacity: 0;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:hover::before {
    left: 0;
    opacity: 1;
  }

  &:not(:hover)::before {
    left: -100%;
    opacity: 0;
    transition: left 0.7s ease, opacity 0.7s ease 0.2s;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  span {
    position: relative;
    z-index: 2;
  }
`;

const ActionButton = ({ 
  children, 
  href, 
  width, 
  height, 
  onClick,
  ...props 
}) => {
  const buttonContent = (
    <span>{children}</span>
  );

  return (
    <StyledButton 
      width={width} 
      height={height} 
      onClick={onClick}
      {...props}
    >
      {href ? (
        <a href={href}>
          {buttonContent}
        </a>
      ) : (
        buttonContent
      )}
    </StyledButton>
  );
};

export default ActionButton;