/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import Footer from '../Footer/Footer';
import ActionButton from '../components/ActionButton';

const heroSection = css`
  background-color: rgba(226, 255, 190, 0.816);
  min-height: 90vh;
  border-bottom: 2px dotted #616161;
  border-right: 2px dotted #616161;
  border-bottom-right-radius: 200px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const heroContent = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  gap: 40px;
  text-align: center;
  padding: 40px 0;
`;

const mainText = css`
  font-size: 4.5rem;
  font-weight: bold;
  color: #333;
  line-height: 1.2;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const gradientText = css`
  background: linear-gradient(45deg, #4CAF50, #2196F3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 6rem;
  font-weight: bold;
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`;

const subText = css`
  font-size: 1.8rem;
  color: #666;
  margin: 20px 0;
  max-width: 800px;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const pricingFeatures = css`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 40px 0;
  flex-wrap: wrap;
`;

const feature = css`
  background: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  text-align: center;
`;

const messageCounter = css`
  font-size: 2.5rem;
  font-weight: bold;
  color: #4CAF50;
  margin: 10px 0;
`;

const campaignButton = css`
  background-color: #000000;
  color: #ffffff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  width: 200px;
  margin-top: 30px;
  
  &:hover {
    transform: scale(1.05);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      to right, 
      #000000 0%,
      #000000 25%,
      rgba(0, 255, 0, 0.5) 50%,
      #000000 75%,
      #000000 100%
    );
    transition: left 0.7s ease, opacity 0.3s ease;
    z-index: 1;
    opacity: 0;
  }

  &:hover::before {
    left: 0;
    opacity: 1;
  }

  span {
    position: relative;
    z-index: 2;
  }
`;

const buttonWrapper = css`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

const Pricing = () => {
  return (
    <>
      <div css={heroSection}>
        <div css={heroContent}>
          <motion.h1 
            css={mainText}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Start for Free with
          </motion.h1>
          
          <motion.div
            css={gradientText}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            100 Free Messages
          </motion.div>

          <motion.p 
            css={subText}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            Begin your journey with no upfront cost. Then pay only for what you send.
          </motion.p>

          <motion.div 
            css={pricingFeatures}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <div css={feature}>
              <h3>Starter Pack</h3>
              <div css={messageCounter}>100</div>
              <p>Free Messages</p>
              <p>Perfect for testing</p>
            </div>
            <div css={feature}>
              <h3>Pay As You Grow</h3>
              <div css={messageCounter}>$0.01</div>
              <p>Per Message</p>
              <p>After free tier</p>
            </div>
            <div css={feature}>
              <h3>Enterprise</h3>
              <div css={messageCounter}>Custom</div>
              <p>Volume Pricing</p>
              <p>For large campaigns</p>
            </div>
          </motion.div>

          <motion.div 
            css={buttonWrapper}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
          >
            <ActionButton 
              href="https://app.sen-due.com/campaign/steps/leads"
              width="200px"
              height="50px"
            >
              Start Campaign
            </ActionButton>
          </motion.div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;