/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { NavLink, Link } from 'react-router-dom';
import { useState } from 'react';
import logo from './assets/sendue_logo.png';
import loginDefaultIcon from './assets/login.png';
import loginHoveredIcon from './assets/login_select.png';
import userDefaultIcon from './assets/landingpagUser.png';
import userHoveredIcon from './assets/landingpageUser_select.png';

const nav = css`
  background-color:  rgba(226, 255, 190, 0.95);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
`;

const navbarContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1300px;
  margin: 0 auto;
`;

const navbarLeft = css`
  display: flex;
  align-items: center;
`;

const navbarLogo = css`
  height: 60px;
  margin-right: 40px;
`;

const navMenu = (isOpen) => css`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    background-color: rgba(226, 255, 190, 0.95);
    z-index: 1000;
  }
`;

const navItem = css`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const navLink = css`
  font-family: 'Severange', sans-serif;
  color: #333;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #333;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover::before {
    transform: scaleX(1);
  }

  &:not(:hover)::before {
    transform-origin: right;
    transition: transform 0.3s ease;
  }

  &.active::before {
    transform: scaleX(1);
  }
`;

const navButtons = css`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const navButton = css`
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  width: 140px;
  height: 45px;

  &:hover {
    background-color: #161616;
    transform: scale(1.05);
  }

  img {
    width: 28px;
    height: 28px;
    margin-right: 5px;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const burgerMenu = css`
  display: none;
  cursor: pointer;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Navbar = ({ isMenuOpen, toggleMenu }) => {
  const [isLoginHovered, setIsLoginHovered] = useState(false);
  const [isDashboardHovered, setIsDashboardHovered] = useState(false);

  return (
    <nav css={nav}>
      <div css={navbarContainer}>
        <div css={navbarLeft}>
        <Link to="/">
            <img css={navbarLogo} src={logo} alt="Sendue Logo" />
          </Link>
          <ul css={navMenu(isMenuOpen)}>
            <li css={navItem}>
              <NavLink css={navLink} exact to="/" activeClassName="active">
                Home
              </NavLink>
            </li>
            <li css={navItem}>
              <NavLink css={navLink} to="/how" activeClassName="active">
                How It Works
              </NavLink>
            </li>
            <li css={navItem}>
              <NavLink css={navLink} to="/pricing" activeClassName="active">
                Pricing
              </NavLink>
            </li>
            <li css={navItem}>
              <NavLink css={navLink} to="/about" activeClassName="active">
                About
              </NavLink>
            </li>
            <li css={navItem}>
              <NavLink css={navLink} to="/contact" activeClassName="active">
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        <div css={navButtons}>
          <button 
            css={navButton}
            onMouseEnter={() => setIsLoginHovered(true)}
            onMouseLeave={() => setIsLoginHovered(false)}
            onClick={() => window.location.href = 'https://app.sen-due.com/login'}
          >
            <img 
              src={isLoginHovered ? loginHoveredIcon : loginDefaultIcon} 
              alt="Login/Signup" 
            />
            Login/Signup
          </button>
          <button 
            css={navButton}
            onMouseEnter={() => setIsDashboardHovered(true)}
            onMouseLeave={() => setIsDashboardHovered(false)}
            onClick={() => window.location.href = 'https://app.sen-due.com'}
          >
            <img 
              src={isDashboardHovered ? userHoveredIcon : userDefaultIcon} 
              alt="Dashboard" 
            />
            Dashboard
          </button>
        </div>
        <div css={burgerMenu} onClick={toggleMenu}>
          ☰
        </div>
      </div>
    </nav>
  );
};

export default Navbar;