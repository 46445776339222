import React from 'react';

const PrivacyPolicy = () => {
    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        minHeight: '100vh',
        backgroundColor: 'transparent',
      },
      card: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '10px',
        padding: '30px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '800px',
        width: '100%',
        marginBottom: '20px',
        textAlign: 'left',
      },
      title: {
        fontSize: '24px',
        marginBottom: '20px',
        color: '#333',
      },
      content: {
        fontSize: '14px',
        color: '#555',
        lineHeight: '1.6',
      },
      section: {
        marginBottom: '20px',
      },
      sectionTitle: {
        fontSize: '18px',
        color: '#333',
        marginBottom: '10px',
      },
    };
  
    return (
      <div style={styles.container}>
        <div style={styles.card}>
          <h1 style={styles.title}>Privacy Policy</h1>
  
          <div style={styles.content}>
            <p><strong>Last updated:</strong> September 03, 2024</p>
            
            <div style={styles.section}>
              <h2 style={styles.sectionTitle}>1. INTRODUCTION</h2>
              <p>
                Welcome to Sendue. We are committed to protecting your personal information and your right to privacy. 
                This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our service.
              </p>
            </div>

            <div style={styles.section}>
              <h2 style={styles.sectionTitle}>2. INFORMATION WE COLLECT</h2>
              <p>
                We collect personal information that you provide to us, including:
                <ul>
                  <li>Personal information (such as name, email address, phone number)</li>
                  <li>Business information</li>
                  <li>Contact lists and lead information</li>
                  <li>Content of messages and posts</li>
                  <li>Payment information</li>
                </ul>
              </p>
            </div>

            <div style={styles.section}>
              <h2 style={styles.sectionTitle}>3. HOW WE USE YOUR INFORMATION</h2>
              <p>
                We use the information we collect for various purposes, including:
                <ul>
                  <li>Providing and maintaining our service</li>
                  <li>Improving and personalizing our service</li>
                  <li>Communicating with you</li>
                  <li>Processing payments</li>
                  <li>Analyzing usage patterns</li>
                </ul>
              </p>
            </div>

            <div style={styles.section}>
              <h2 style={styles.sectionTitle}>4. SHARING OF YOUR INFORMATION</h2>
              <p>
                We may share your information with:
                <ul>
                  <li>Service providers and business partners</li>
                  <li>Legal and regulatory authorities</li>
                  <li>Potential buyers or investors (in the event of a sale, merger, or acquisition)</li>
                </ul>
              </p>
            </div>

            <div style={styles.section}>
              <h2 style={styles.sectionTitle}>5. YOUR PRIVACY RIGHTS</h2>
              <p>
                Depending on your location, you may have certain rights regarding your personal information, including:
                <ul>
                  <li>Access to your personal information</li>
                  <li>Correction of inaccurate information</li>
                  <li>Deletion of your information</li>
                  <li>Objection to or restriction of processing</li>
                  <li>Data portability</li>
                </ul>
              </p>
            </div>

            <div style={styles.section}>
              <h2 style={styles.sectionTitle}>6. CONTACT US</h2>
              <p>
                If you have any questions about this Privacy Policy, please contact us at:
                <br />
                Email: support@sendue.com
                <br />
                Address: 123 Sendue Street, Tech City, TC 12345
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default PrivacyPolicy;