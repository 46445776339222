/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect, useRef, useCallback } from 'react';
import HeroSection from './HeroSection';
import FeatureSection from './FeatureSection';
import Footer from '../Footer/Footer';

// Import the images
import featureImage from './assets/Feature_image.png';
import newFeatureImage from './assets/guy_laptop.png';
import newFeatureImage2 from './assets/Feature_massages.png';
import newFeatureImage3 from './assets/features_due.png';

const landingPageContainer = css`
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [visibleFeatures, setVisibleFeatures] = useState([]);
  const featureSectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const observerCallback = useCallback((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisibleFeatures((prev) => [...prev, entry.target]);
      }
    });
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3,
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    featureSectionRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      featureSectionRefs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [featureSectionRefs, observerCallback]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div css={landingPageContainer}>
      <HeroSection />
      <FeatureSection
        ref={featureSectionRefs[0]}
        title="Send"
        subtitle="Guaranteed open"
        description={[
          "Effortlessly import contacts from various sources.",
          "Create engaging posts with our intuitive editor.",
          "Send messages to unlimited recipients with ease."
        ]}
        imageSrc={featureImage}
        isVisible={visibleFeatures.includes(featureSectionRefs[0].current)}
      />
      <FeatureSection
        ref={featureSectionRefs[1]}
        title="Unlimited"
        subtitle="No Spam Limits"
        description={[
          "Using our platform you can...",
          "Use your personal phone to get contacts.",
          "Send From Our platform with NO SPAM consideration."
        ]}
        imageSrc={newFeatureImage}
        isVisible={visibleFeatures.includes(featureSectionRefs[1].current)}
        imageOnRight
      />
      <FeatureSection
        ref={featureSectionRefs[2]}
        title="Messages"
        subtitle="To Specified Contacts"
        description={[
          "Send to relevant contacts.",
          "Messages that promote your Business.",
          "Guarantee that messages will be seen."
        ]}
        imageSrc={newFeatureImage2}
        isVisible={visibleFeatures.includes(featureSectionRefs[2].current)}
      />
      <FeatureSection
        ref={featureSectionRefs[3]}
        title="Due"
        subtitle="Send in due time"
        description={[
          "Set time for campaign deployment.",
          "Send immediately if desired.",
          "Control your campaign based on your needs."
        ]}
        imageSrc={newFeatureImage3}
        isVisible={visibleFeatures.includes(featureSectionRefs[3].current)}
        imageOnRight
      />
      <Footer />
    </div>
  );
};

export default LandingPage;