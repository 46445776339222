/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { FileText, Building2, PenLine, Rocket } from 'lucide-react';
import { useState, useEffect } from 'react';

const flowContainer = css`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
`;

const flowItemsContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
`;

const itemContainer = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const circle = css`
  width: 96px;
  height: 96px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const title = css`
  font-size: 1.25rem;
  color: #333;
  margin-top: 16px;
  text-align: center;
  font-weight: 600;
`;

const description = css`
  font-size: 0.875rem;
  color: #666;
  text-align: center;
  max-width: 200px;
  margin-top: 8px;
  line-height: 1.4;
`;

const line = css`
  position: absolute;
  top: 48px;
  left: calc(50% + 48px);
  height: 2px;
  transform-origin: left;
`;

const ProcessFlow = () => {
  const [currentStep, setCurrentStep] = useState(-1);

  const flowItems = [
    {
      icon: FileText,
      title: 'Add Leads',
      description: 'Import your contacts list seamlessly',
      color: '#F4903F'
    },
    {
      icon: Building2,
      title: 'Create Business Profile',
      description: 'Set up your professional business identity',
      color: '#40B7E6'
    },
    {
      icon: PenLine,
      title: 'Create Post',
      description: 'Design your campaign message',
      color: '#8B3C97'
    },
    {
      icon: Rocket,
      title: 'Deploy Campaign',
      description: 'Launch your campaign to your audience',
      color: '#4CAF50'
    }
  ];

  useEffect(() => {
    if (currentStep < flowItems.length) {
      const timer = setTimeout(() => {
        setCurrentStep(prev => prev + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [currentStep]);

  return (
    <div css={flowContainer}>
      <div css={flowItemsContainer}>
        {flowItems.map((item, index) => (
          <div key={index} css={itemContainer}>
            {/* Connecting Line */}
            {index < flowItems.length - 1 && currentStep >= index && (
              <motion.div
                css={[line, css`width: calc(100% - 96px);`]}
                style={{ background: item.color }}
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{ duration: 0.5 }}
              />
            )}

            {/* Circle and Content */}
            {currentStep >= index && (
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5, type: "spring", stiffness: 200 }}
                style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <div 
                  css={circle}
                  style={{ border: `2px solid ${item.color}` }}
                >
                  <item.icon 
                    size={32}
                    color={item.color}
                  />
                </div>
                <h3 css={title}>{item.title}</h3>
                <p css={description}>{item.description}</p>
              </motion.div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProcessFlow;