import React from 'react';
import styled from 'styled-components';
import heroImage from './assets/About.png';  // Import the hero image for the About page
import Footer from '../Footer/Footer';

// Hero Section Styles - same as the LandingPage
const Hero = styled.div`
  background-color: rgba(226, 255, 190, 0.816);
  height: 80vh;
  border-bottom: 2px dotted #616161;
  border-right: 2px dotted #616161;
  border-bottom-right-radius: 200px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeroContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const HeroText = styled.div`
  flex: 1;
  padding: 20px;
  padding-right: 40px;
  text-align: left;

  h1 {
    font-family: inherit;
    color: #333;
    font-size: 3.5rem;
    margin-bottom: 20px;
  }

  p {
    font-family: inherit;
    color: #666;
    font-size: 1.5rem;
    margin-bottom: 80px;
  }

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px;
  }
`;

// HeroImage container with transparent background
const HeroImage = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;  // Transparent background for the image container

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    justify-content: center;

    img {
      max-width: 100%;
    }
  }
`;

// About Page Component
const About = () => {
  return (
    <>
      <Hero>
        <HeroContent>
          <HeroText>
            <h1>About Us</h1>
            <p>We are dedicated to providing affordable and easy access to everyone.<br />
              Our platform empowers businesses to send WhatsApp message campaigns effortlessly.<br />
              Take your business communication to the next level with ease and efficiency.
            </p>
          </HeroText>
          <HeroImage>
            {/* Display the hero image for the About page */}
            <img src={heroImage} alt="About Us" />
          </HeroImage>
        </HeroContent>
      </Hero>
      <Footer />
    </>
  );
};

export default About;
