import React, { useRef } from 'react';
import styled from 'styled-components';
import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  max-width: 1000px;
  margin: 0 auto;
  gap: 40px;

  @media (min-width: 768px) {
    flex-direction: ${props => props.imageOnRight ? 'row-reverse' : 'row'};
    align-items: flex-start;
  }
`;

const ImageWrapper = styled(motion.div)`
  width: 100%;
  max-width: 400px;
  perspective: 1000px;
`;

const StyledImage = styled(motion.img)`
  width: 100%;
  height: auto;
  border-radius: 12px;
  transform-style: preserve-3d;
  will-change: transform;
`;

const Content = styled(motion.div)`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
    flex: 1;
  }
`;

const Title = styled(motion.h2)`
  font-size: 3rem;
  color: #4CAF50;
  margin-bottom: 20px;
`;

const Subtitle = styled(motion.h3)`
  font-size: 2rem;
  color: #4CAF50;
  margin-bottom: 30px;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 120%;
    height: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,7.5 Q25,0 50,7.5 T100,7.5' stroke='%234CAF50' fill='none' stroke-width='5'/%3E%3C/svg%3E");
    background-size: 100px 100%;
    background-repeat: repeat-x;
    opacity: ${props => props.isVisible ? 1 : 0};
    transition: opacity 1s ease-out;
    animation: ${props => props.isVisible ? 'wave 2s linear infinite' : 'none'};
  }

  @keyframes wave {
    0% { background-position-x: 0; }
    100% { background-position-x: 100px; }
  }
`;

const Description = styled(motion.div)`
  font-size: 1.2rem;
  color: #333;
  line-height: 1.6;
`;

const FeatureItem = styled(motion.p)`
  margin: 10px 0;
`;

const FeatureSection = React.forwardRef(({ 
  title, 
  subtitle, 
  description, 
  imageSrc, 
  isVisible = true, 
  imageOnRight = false 
}, ref) => {
  // Motion values for mouse movement
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  // Smoother spring movement
  const moveX = useSpring(x, {
    stiffness: 100,
    damping: 30
  });
  const moveY = useSpring(y, {
    stiffness: 100,
    damping: 30
  });

  // Increased movement range to 15px and subtle rotation
  const transformX = useTransform(moveX, [-100, 100], [-15, 15]);
  const transformY = useTransform(moveY, [-100, 100], [-15, 15]);
  const rotateX = useTransform(moveY, [-100, 100], [5, -5]);
  const rotateY = useTransform(moveX, [-100, 100], [-5, 5]);

  function handleMouseMove(event) {
    const rect = event.currentTarget.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    
    const mouseX = event.clientX - centerX;
    const mouseY = event.clientY - centerY;

    x.set(mouseX);
    y.set(mouseY);
  }

  function handleMouseLeave() {
    x.set(0);
    y.set(0);
  }

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const imageAnimation = {
    hidden: { 
      opacity: 0, 
      x: imageOnRight ? 100 : -100 
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  return (
    <Section ref={ref} imageOnRight={imageOnRight}>
      <ImageWrapper
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={imageAnimation}
      >
        <StyledImage
          src={imageSrc}
          alt={title}
          style={{
            x: transformX,
            y: transformY,
            rotateX: rotateX,
            rotateY: rotateY,
            transition: 'transform 0.2s ease-out'
          }}
        />
      </ImageWrapper>

      <Content
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <Title variants={fadeInUp}>{title}</Title>
        <Subtitle isVisible={isVisible} variants={fadeInUp}>{subtitle}</Subtitle>
        <Description variants={fadeInUp}>
          {description.map((item, index) => (
            <FeatureItem 
              key={index}
              variants={fadeInUp}
              custom={index}
            >
              {item}
            </FeatureItem>
          ))}
        </Description>
      </Content>
    </Section>
  );
});

export default FeatureSection;