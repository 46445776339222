/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import Footer from '../../Footer/Footer';
import ProcessFlow from './How_feature';
import ActionButton from '../../components/ActionButton';
import heroImage from '../assets/how_image.jpg';
import Tutorial from './Tutorial';

const heroSection = css`
  background-color: rgba(226, 255, 190, 0.816);
  min-height: 80vh;
  border-bottom: 2px dotted #616161;
  border-right: 2px dotted #616161;
  border-bottom-right-radius: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
`;

const heroContent = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const leftSection = css`
  flex: 1;
  text-align: left;
`;

const stepsTitle = css`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: bold;
`;

const stepsList = css`
  list-style: none;
  padding: 0;
  margin: 30px 0;
`;

const step = css`
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 1.2rem;
  color: #333;
`;

const stepNumber = css`
  background: #4CAF50;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  font-weight: bold;
`;

const infoText = css`
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 30px 0;
`;

const imageContainer = css`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  background-color: transparent;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const buttonWrapper = css`
  margin-top: 30px;
`;

const mainContent = css`
  background-color: white;
  padding: 60px 0;
`;

const How = () => {
  return (
    <>
      <div css={heroSection}>
        <div css={heroContent}>
          <motion.div 
            css={leftSection}
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <h2 css={stepsTitle}>In 4 simple steps, you'll launch your campaign:</h2>
            <ul css={stepsList}>
              <motion.li 
                css={step}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                <span css={stepNumber}>1</span>
                <div>Add your leads list 📋</div>
              </motion.li>
              <motion.li 
                css={step}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                <span css={stepNumber}>2</span>
                <div>Create your business profile 🏢</div>
              </motion.li>
              <motion.li 
                css={step}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                <span css={stepNumber}>3</span>
                <div>Create your post message ✍️</div>
              </motion.li>
              <motion.li 
                css={step}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                <span css={stepNumber}>4</span>
                <div>Launch your campaign 🚀</div>
              </motion.li>
            </ul>
            <motion.p 
              css={infoText}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
            >
              Your leads will receive your post from your business profile.<br/><br/>
              We'll provide and configure the sending number - just focus on your campaign!
            </motion.p>
            
            <motion.div 
              css={buttonWrapper}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <ActionButton 
                href="https://app.sen-due.com/campaign/steps/leads"
                width="200px"
                height="50px"
              >
                Start Campaign
              </ActionButton>
            </motion.div>
          </motion.div>
          
          <motion.div 
            css={imageContainer}
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <img src={heroImage} alt="How It Works" />
          </motion.div>
        </div>
      </div>

      <div css={mainContent}>
        <ProcessFlow />
        <Tutorial />
      </div>
      
      <Footer />
    </>
  );
};

export default How;