/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './Landing_page/Landing_page';
import How from './Landing_page/how_page/How';
import Pricing from './Landing_page/Pricing';
import PrivacyPolicy from './Footer/Footer_pages/PrivacyPolicy';
import TermsOfService from './Footer/Footer_pages/TermsofService';
import Contact from './Landing_page/Contact';
import About from './Landing_page/About';
import Navbar from './Landing_page/Navbar';

const appStyles = css`
  text-align: center;
`;

const navbarWrapperStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`;

const contentStyles = css`
  padding-top: 80px; /* Height of navbar */
`;

function App() {
  return (
    <Router>
      <div css={appStyles}>
        <div css={navbarWrapperStyles}>
          <Navbar />
        </div>
        <main css={contentStyles}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/how" element={<How />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;